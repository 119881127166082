import $ from 'jquery';

export default class cassielBaseDz {

    current = null;

    constructor(dropzone = null) {
        this.dropzone = dropzone;
    }

    loadImages(list, base) {

        this.dropzone.removeAllFiles();

        for (let i = 0; i < list.length; i++) {
            this.dropzone.emit("addedfile", list[i]);
            this.dropzone.emit("thumbnail", list[i], base + list[i].url);
            this.dropzone.emit("complete", list[i]);
            this.dropzone.files.push(list[i]);
        }
    }

    getImages() {
        let list = [];

        for (let i = 0; i < this.dropzone.files.length; i++) {
            if ("undefined" === typeof (this.dropzone.files[i].url)) {
                list[i] = JSON.parse(this.dropzone.files[i].previewElement.querySelector('[name="image"]').value)

            } else {
                list[i] = {
                    'url': this.dropzone.files[i].url,
                    'size': this.dropzone.files[i].size,
                    'caption': this.dropzone.files[i].caption,
                    'alt': this.dropzone.files[i].alt,
                    'title': this.dropzone.files[i].title,
                    'isMock': true
                }
            }
        }

        return list;

    }

    addFile(file, url = null) {

        let data = {
            'url': file.url,
            'size': file.size,
            'name': file.name,
            'isMock': true
        }

        if (file.url === undefined) {
            data.url = url
        }

        if (file.previewElement.querySelector('[name="caption"]') &&
            file.previewElement.querySelector('[name="caption"]').value === 'undefined') {
            file.previewElement.querySelector('[name="caption"]').value = file.caption;
            data.caption = file.caption;
        } else {
            data.caption = '';
        }

        if (file.previewElement.querySelector('[name="alt"]') &&
            file.previewElement.querySelector('[name="alt"]').value === 'undefined') {
            file.previewElement.querySelector('[name="alt"]').value = file.alt;
            data.alt = file.alt;
        } else {
            data.alt = '';
        }

        if (file.previewElement.querySelector('[name="title"]') &&
            file.previewElement.querySelector('[name="title"]').value === 'undefined') {
            file.previewElement.querySelector('[name="title"]').value = file.title;
            data.title = file.title;
        } else {
            data.title = '';
        }


        file.previewElement.querySelector('[name="image"]').value = JSON.stringify(data);
    }

    sendFile(editor, formData, file) {

        if (file.previewElement.querySelector('[name="image"]').value === 'undefined') {
            formData.append("widget", editor.opts.widget);
            formData.append("field", editor.opts.entity);
        } else {

            let image = JSON.parse(file.previewElement.querySelector('[name="image"]').value)
            formData.append("url", image.url);

            if (file.previewElement.querySelector('[name="caption"]') &&
                file.previewElement.querySelector('[name="caption"]').value !== 'undefined') {
                formData.append("caption", file.previewElement.querySelector('[name="caption"]').value);
            }

            if (file.previewElement.querySelector('[name="alt"]') &&
                file.previewElement.querySelector('[name="alt"]').value !== 'undefined') {
                formData.append("alt", file.previewElement.querySelector('[name="alt"]').value);
            }

            if (file.previewElement.querySelector('[name="title"]') &&
                file.previewElement.querySelector('[name="title"]').value !== 'undefined') {
                formData.append("title", file.previewElement.querySelector('[name="title"]').value);
            }

            formData.append("widget", editor.opts.widget);
            formData.append("field", editor.opts.entity);

            if(editor.opts.cassielId !== null) {
                formData.append("cassielId", editor.opts.cassielId);
            }
        }
    }

    removeFile(editor, file, base) {
        let x = confirm('Do you want to delete?');
        if (!x) return false;

        let url = "";
        if (file.url) {
            url = file.url;
        } else {
            let image = JSON.parse(file.previewElement.querySelector('[name="image"]').value)
            url = image.url
        }

        let headers = {};
        if (typeof(Storage)!=="undefined") {
            headers['X-AUTH-TOKEN'] = localStorage.getItem('token').replaceAll( '"','');
        }
        $.ajax({
            type: 'POST',
            url: base + editor.opts.cassielDeleteImageApi,
            data: url,
            headers: headers,
            success: function () {
                file.previewElement.remove();
            }
        });
    }

    /**
     * When changing caption, title or alt
     * @param element
     */
    updateFile(element) {

        let i = 0;
        let child = element;
        if(child){
            while( (child = child.previousSibling) != null ) i++;

            let data = JSON.parse(element.querySelector('[name="image"]').value)

            if (element.querySelector('[name="caption"]') &&
                element.querySelector('[name="caption"]').value !== 'undefined') {
                if(this.dropzone.files[i - 1] !== undefined) {
                    data.caption = this.dropzone.files[i - 1].caption = element.querySelector('[name="caption"]').value
                }
                else{
                    data.caption = element.querySelector('[name="caption"]').value
                }
            } else {
                data.caption = '';
            }

            if (element.querySelector('[name="alt"]') &&
                element.querySelector('[name="alt"]').value !== 'undefined') {
                if(this.dropzone.files[i - 1] !== undefined) {
                    data.alt = this.dropzone.files[i - 1].alt = element.querySelector('[name="alt"]').value;
                }
                else {
                    data.alt = element.querySelector('[name="alt"]').value;
                }
            } else {
                data.alt = '';
            }

            if (element.querySelector('[name="title"]') &&
                element.querySelector('[name="title"]').value !== 'undefined') {
                if(this.dropzone.files[i - 1] !== undefined) {
                    data.title = this.dropzone.files[i - 1].title = element.querySelector('[name="title"]').value;
                }
                else {
                    data.title = element.querySelector('[name="title"]').value;
                }
            } else {
                data.title = '';
            }

            element.querySelector('[name="image"]').value = JSON.stringify(data);
        }
    }
}