import Vue from 'vue'
import Vuex from 'vuex'
import themeConfig from '@/../themeConfig.js'

import authModule from './modules/auth';
import messageModule from './modules/messages';
import menuModule from './modules/menu';

Vue.use(Vuex);

/* eslint-disable */
export default new Vuex.Store({
  state: {
    theme: themeConfig.theme,
    loaderSpinner: false,
  	isSidebarActive: false,
  	isSidebarReduced: themeConfig.sidebarCollapsed,
  	sidebarWidth: "default",
    themeColor: themeConfig.themeColor,

  },
  mutations: {
  	//This is for Sidbar trigger in mobile
  	IS_SIDEBAR_ACTIVE(state, value) {
        state.isSidebarActive = value;
    },
    //This is for Sidebar toggle in desktop
    TOGGLE_REDUCE_SIDEBAR(state, val) {
        state.isSidebarReduced = val;
    },
    //This is for Themeset
    SET_THEME(state, val) {
        state.theme = val;
    },
    //This is for Theme color
    SET_THEME_COLOR(state, val) {
        state.themeColor = val;
    },
    SET_SIDEBAR_WIDTH(state, width) {
        state.sidebarWidth = width;
    },
    SET_LOADER_SPINNER(state, val){
  	    state.loaderSpinner = val;
        if(val){
            document.getElementById('global-loading-container').style.display = 'block';
        }
        else{
            document.getElementById('global-loading-container').style.display = 'none';
        }
    }
  },
  actions: {
  	updateSidebarWidth({ commit }, width) {
        commit('SET_SIDEBAR_WIDTH', width);
    },

    setLoaderSPinner({commit}) {
  	    commit('SET_LOADER_SPINNER', true);
    },

    clearLoaderSPinner({commit}) {
          commit('SET_LOADER_SPINNER', false);
      }
  },

  modules: {
    auth: authModule,
    globalMessages: messageModule,
    menuModule:menuModule,
  }
})
