import axios from 'axios'
import store from './store/store.js';

const ACLChecker = {
    user: null, userRoles: null,

    /**
     * check whether the requested permission exists in the User's ACL Schema
     * @param {Object} context
     * @returns {boolean}
     */
    hasAccess: function (context) {

        if (!context) {
            return true
        }
        let Role = "";
        if (context && context.role) {
            Role = context.role;
        } else {
            Role = 'ROLE_' + context.widgetName + '_' + context.entityName + '_' + context.action;
        }
        if (!ACLChecker.userRoles) {
            ACLChecker.userRoles = store.getters['auth/getRoles'];
        }
        let authenticated = false;
        if (ACLChecker.userRoles.includes(Role)) {
            return true;
        }
        if (new RegExp(Role).test(ACLChecker.userRoles)) {
            // console.log(Role)
            return true;
            // At least one match
        }
        return authenticated;
    },

    /**
     * Check if user is logged in and if he has a state in the store
     * @param route
     * @returns {Promise<*>}
     */
    isAuthorized: async function (route) {
        if (route.name === 'Login') {
            return Promise.resolve(true);
        }

        const user = store.getters['auth/getUser'];
        const roles = store.getters['auth/getRoles'];
        let headers = {};

        const token = store.getters['auth/getToken'];
        if (token) {
            headers['Authorization'] = token;
        }

        if (user && roles) {
            ACLChecker.user = user;
            ACLChecker.userRoles = roles;
            return Promise.resolve(true);
        } else {
            return await ACLChecker.doRefresh(headers);
        }
    },

    /**
     * scan the user's acl against the route's acl to determine if he can access this route or not
     * @param route
     * @returns {Promise<*>}
     */
    isAuthenticated: async function (route) {
        if (['*', 'public'].includes(route.meta.acl)) {
            return true;
        } else if (['private'].includes(route.meta.acl)) {
            if (ACLChecker.user) {
                return true;
            }
        } else {
            if (ACLChecker.user) {
                //check if the requested operation is allowed
                //null means don't check
                let authenticated = ACLChecker.hasAccess(route.meta.acl);
                return authenticated;
            } else {
                return false;
            }
        }
    },

    /**
     * generates the required messages that should be displayed depending on conditions and criteria
     */
    populateAuthorizationMessages: function () {
        let messages = store.getters['globalMessages/list'];

        //if logout was fired, do not throw unauthorized messages
        let pushMessage = true;
        if (messages && messages.length > 0) {
            messages.forEach((oneMessage) => {
                if (oneMessage.text.includes("User Logout Successfully")) {
                    pushMessage = false;
                }

                if (oneMessage.text.includes("Please Login to proceed!")) {
                    pushMessage = false;
                }
            });
        }

        if (pushMessage) {
            store.dispatch('globalMessages/push', {
                type: 'danger', title: "Access Denied", text: "Please Login to proceed!"
            });
        }
    },

    /**
     * Check if user is authorized and authenticated to access the requested route.
     * @param to
     * @param from
     * @param next
     * @returns {Promise<*>}
     */
    checkAuthenticatedUser: async function (to, from, next) {
        let authorizedUser = false;
        await ACLChecker.isAuthorized(to).then(() => {
            authorizedUser = true;
        }).catch(() => {
            authorizedUser = false;
        });

        let authenticatedUser = await ACLChecker.isAuthenticated(to);

        //if not logged in go back to login page
        if (!authorizedUser) {
            ACLChecker.populateAuthorizationMessages();
            if (to.name !== 'Login') {
                store.dispatch('auth/clear');
                return next({name: 'Login'});
            }
        }

        //if logged in but not allowed go back to dashboard
        if (authorizedUser && !authenticatedUser) {
            setTimeout(() => {
                store.dispatch('globalMessages/push', {
                    type: 'danger', title: "Access Denied", text: "The Section you are trying to access is not allowed!"
                });
            }, 200);

            if (to.name !== 'dashboard') {
                return next({name: 'dashboard'});
            }
        }

        return next();
    },

    /**
     * Method that refreshes the OAuth tokens
     * @param {Object} headers
     * @returns {Promise<AxiosResponse<any>>}
     */
    doRefresh: async function (headers) {
        const axiosInstance = axios.create({
            baseURL: process.env.CMS_BACKEND_BASE_URL, headers: {'Content-Type': 'application/json'}
        });

        const refreshTokenUrl = '/cassiel-backend-core/refreshToken';
        const refreshToken = store.getters['auth/getRefreshToken'];

        return await axiosInstance({
            url: refreshTokenUrl,
            method: 'POST',
            headers: headers,
            data: {
                'refreshToken': refreshToken
            }
        })
            .then(response => {
                if (response && response['data']) {
                    response = response['data']['data'];
                    store.dispatch('auth/setUser', response).then(() => {
                        ACLChecker.user = store.getters['auth/getUser'];
                        ACLChecker.userACL = store.getters['auth/getACL'];
                        return Promise.resolve(true);
                    });
                } else {
                    return Promise.reject("Invalid Request!");
                }
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    refreshToken: async function () {
        let headers = {};

        const token = store.getters['auth/getToken'];
        if (token) {
            headers['Authorization'] = token;
        }

        return await ACLChecker.doRefresh(headers);

    }
};

export default ACLChecker;