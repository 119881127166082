import Vue from 'vue';

import Router from 'vue-router';
Vue.use(Router);
import routes from './config/routes.js';
const router = new Router({
    mode: 'history',
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
    base: process.env.BASE_URL,
    routes: routes
});

import ACLChecker from './acl.js';
router.beforeEach(ACLChecker.checkAuthenticatedUser);
export default router
