import FroalaEditor from "froala-editor";
import $ from "jquery";

import apiList from "/src/config/endpoints.json";
import axios from "axios";

import cassielBase from "@/assets/froala/plugins/cassielBase";
import store from "../../../store/store";

const WEToken = store.getters["auth/getToken"];
let WEHeaders = {};
if (WEToken) {
  WEHeaders["X-AUTH-TOKEN"] = WEToken;
}

const base = axios.create({
  baseURL: process.env.VUE_APP_CMS_BACKEND_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

const PLUGIN_NAME = "cassielWidgetEmbed";

let current;
let editPopup = PLUGIN_NAME + "Edit.popup";
let mainPopup = PLUGIN_NAME + ".popup";
let editButtons = PLUGIN_NAME + "EditButtons";
let editCommand = PLUGIN_NAME + "Edit";
let removeCommand = PLUGIN_NAME + "Remove";
let component = "widgetembed";

FroalaEditor.DefineIcon(PLUGIN_NAME, { NAME: "star", SVG_KEY: "star" });
FroalaEditor.RegisterCommand(PLUGIN_NAME, {
  title: "Widget Embed",
  icon: PLUGIN_NAME,
  undo: false,
  focus: false,
  plugin: PLUGIN_NAME,
  callback: function () {
    this[PLUGIN_NAME].openModal(2);
  },
});

FroalaEditor.POPUP_TEMPLATES[mainPopup] = "[_CUSTOM_LAYER_]";
FroalaEditor.POPUP_TEMPLATES[editPopup] = "[_BUTTONS_]";

Object.assign(FroalaEditor.DEFAULTS, {
  [editButtons]: [editCommand, removeCommand],
  cassielWidgetEmbedApiURL: apiList.froalaWidgetEmbedList,
  cassielWidgetEmbedParams: [],
  cassielWidgetEmbedConfigs: [],
});

FroalaEditor.DefineIcon(removeCommand, { NAME: "trash", SVG_KEY: "remove" });
FroalaEditor.RegisterCommand(removeCommand, {
  title: "Delete",
  undo: true,
  focus: false,
  callback: function () {
    let cb = new cassielBase(this, editPopup);
    cb.current = current;
    cb.deleteCurrent();
  },
});

FroalaEditor.DefineIcon(editCommand, { NAME: "edit", SVG_KEY: "edit" });
FroalaEditor.RegisterCommand(editCommand, {
  title: "Edit",
  undo: true,
  focus: false,
  callback: function () {
    this[PLUGIN_NAME].openModal(1);
  },
});

FroalaEditor.PLUGINS[PLUGIN_NAME] = function (editor) {
  $("document").ready(function () {
    $(document).on("DOMSubtreeModified", ".dz-remove", function () {
      let $this = $(this);
      if ($this.html() === "Cancel upload") {
        $(".fr-modal-head-line > button").prop("disabled", true);
      } else {
        $(".fr-modal-head-line > button").prop("disabled", false);
      }
    });
  });

  let cassielEmbed = false;

  let flag = 0;

  let modal;

  // let rand = Math.floor(Math.random() * 101);
  let modalId = PLUGIN_NAME + "Modal" + editor.id;
  let insertClass = PLUGIN_NAME + "Insert" + editor.id;

  let head;
  let body;

  function _init() {
    initModal();
    $(document).on("click", "." + insertClass, function () {
      insertHtml();
    });

    $(document).on("change", ".cassielWidgetEmbed-select", function () {
      let val = $(".cassielWidgetEmbed-select").find(":selected").val();
      let inputs = editor.opts.cassielWidgetEmbedParams[val];
      $(".cassielWidgetEmbed-inputs").html(inputs);
    });

    editor.events.$on(
      editor.$el,
      "click",
      'cassielembed[data-name="' + component + '"]',
      (e) => {
        let cb = new cassielBase(editor, editPopup, editButtons);
        cb.edit(e);

        current = $(e.currentTarget);
      }
    );
  }

  async function initModal() {
    if (modal) return;
    // rand = Math.floor(Math.random() * 101);

    let widgets = '<option value="" >-- SELECT --</option>';
    let params = {};
    let configs = {};

    await base({
      url: editor.opts.cassielWidgetEmbedApiURL,
      method: "GET",
      dataType: "json",
      headers: WEHeaders,
    }).then((result) => {
      result.data.forEach((el) => {
        let preview = "";
        if (el.preview_field !== null) {
          preview = el.preview_field;
        }

        let temp = el.params.replace(/\\\\/g, "\\");
        temp = JSON.parse(temp);

        configs[el.id] = {};
        temp.forEach((x) => {
          configs[el.id][x.name] = x;
        });

        el.html = el.html.replaceAll("userToken", WEToken);
        el.html = el.html.replaceAll("<\\/", "</");
        params[el.id] = el.html;

        widgets +=
          "<option " +
          'data-template="' +
          el.template +
          '" ' +
          'data-component="' +
          el.name +
          '" ' +
          'data-widget="' +
          el.widget +
          '" ' +
          'data-name="' +
          el.display_name +
          '" ' +
          'data-preview-field="' +
          preview +
          '" ' +
          'value="' +
          el.id +
          '">' +
          el.display_name +
          "</option>";
      });
    });

    editor.opts.cassielWidgetEmbedConfigs = configs;
    editor.opts.cassielWidgetEmbedParams = params;

    head =
      "<h4> Cassiel Embed </h4>" +
      '<button class="btn btn-primary ' +
      insertClass +
      '" style="float:right; margin-right:50px;margin-top: 4px;">Insert</button>';

    body =
      '<div class="form-group col-md-offset-3 col-md-6" style="padding: 10px;text-align: left; margin: auto">' +
      "<label>Select Widget</label>" +
      '<select class="form-control cassielWidgetEmbed-select" id="cassielWidgetEmbed-select">' +
      widgets +
      "</select>" +
      "<br>" +
      '<div class="cassielWidgetEmbed-inputs" style="position: relative"></div>' +
      "</div>";

    let modalHash = editor.modals.create(modalId, head, body);
    modal = modalHash.$modal;
    head = modalHash.$head;
    body = modalHash.$body;

    $(".fr-modal-wrapper").css("width", "70%");
  }

  function openModal(_flag) {
    flag = _flag;

    if (flag !== 1) {
      resetModal();
      editor.modals.show(modalId);
      return;
    }

    let data = current.data();
    let option = data.id;
    let params = data.params;

    if (option === undefined) {
      resetModal();
      editor.modals.show(modalId);
      return;
    }

    let configs = editor.opts.cassielWidgetEmbedConfigs[option];
    if (configs === undefined) {
      resetModal();
      editor.modals.show(modalId);
      return;
    }

    let $widget = $("#cassielWidgetEmbed-select");
    $widget.find('option[value="' + option + '"]').prop("selected", true);

    let inputs = editor.opts.cassielWidgetEmbedParams[option];
    let inputList = $(".cassielWidgetEmbed-inputs");
    inputList.html(inputs);

    let groups = [];
    for (const [key, value] of Object.entries(configs)) {
      if (value.group !== undefined && value.group.length > 0) {
        if (groups[value.group] === undefined) groups[value.group] = [];
        groups[value.group][key] = value;
        continue;
      }

      setInputValues(key, value, params, inputList);
    }

    for (const [name, list] of Object.entries(groups)) {
      for (const [key, value] of Object.entries(list)) {
        setGroupInputValues(key, value, params, name);
      }
    }

    editor.modals.show(modalId);
  }

  function insertHtml() {
    let $widget = $("#cassielWidgetEmbed-select");

    let widget = $widget.find("option:selected");
    let data = widget.data();

    let preview = data.previewField;

    let list = {};
    list.template = data.template;
    $(
      ".cassielWidgetEmbed-inputs input, .cassielWidgetEmbed-inputs textarea"
    ).each(function () {
      let el = $(this);

      if (el.attr("name") !== undefined) {
        if (el.attr("name").includes("[]")) {
          if (list[el.attr("name").replace("[]", "")] === undefined) {
            list[el.attr("name").replace("[]", "")] = [];
          }
          list[el.attr("name").replace("[]", "")].push(el.val());
        } else {
          if (el.attr("type") === "radio" || el.attr("type") === "checkbox") {
            if (el.is(":checked")) {
              list[el.attr("name")] = el.val();
            }
          } else {
            list[el.attr("name")] = el.val();
          }
        }
      }
    });

    let show = "";
    if (list[preview] !== undefined && list[preview].length > 0) {
      if (Array.isArray(list[preview])) {
        show = list[preview].join(", ");
      } else {
        show = list[preview];
      }
    }

    let html = JSON.stringify({ "Embed Name": data.name, Preview: show });
    let embedChild = $(
      '<code style="' +
        "    direction: ltr;\n" +
        "    text-align: left;\n" +
        "    display: inline-block;\n" +
        '    padding: 5px;"/>'
    ).html(html);

    let embedParent = $("<cassielembed/>")
      .attr("data-name", "widgetembed")
      .attr("data-widget", data.widget)
      .attr("data-entity", data.widget)
      .attr("data-component", data.component)
      .attr("data-id", widget.val())
      .attr("data-params", JSON.stringify(list))
      .attr("contenteditable", "false")
      .append(embedChild);

    let embedContainer = $('<div class="cassielembed"/>').append(embedParent);

    let cb = new cassielBase(editor);
    cb.insertHtml(flag, embedContainer.html(), current);
    flag = 0;

    $(editor.selection.element())
      .closest(".form-group")
      .find("textarea")
      .val(editor.el.innerHTML);

    resetModal();
    editor.modals.hide(modalId);
  }

  function resetModal() {
    $(".cassielWidgetEmbed-inputs").html("");
    $(".cassielWidgetEmbed-select").val("");
  }

  function hideEditPopup() {
    editor.popups.hide("cassielWidgetEmbedEdit.popup");
  }

  function deleteElem() {
    cassielEmbed.remove();
    hideEditPopup();
  }

  function setInputValues(key, value, params, inputList) {
    if (params[key] === undefined) return;

    if (value.key === "textType") {
      inputList.find('[name="' + key + '"]').val(params[key]);
    } else if (value.key === "textareaType") {
      inputList.find('[name="' + key + '"]').text(params[key]);
    } else if (value.key === "imageType") {
      if (params[key].length > 0) {
        inputList.find('[name="' + key + '"]').val(params[key]);
        inputList.find("." + key + "image").css("display", "block");
        inputList
          .find("." + key + "src")
          .attr("src", process.env.VUE_APP_CMS_BACKEND_BASE_URL + params[key]);
      }
    } else if (value.key === "linkType") {
      inputList.find('[name="' + key + '"]').val(params[key]);
      inputList.find('[name="url_' + key + '"]').val(params["url_" + key]);
    } else if (value.key === "galleryType") {
      // eslint-disable-next-line no-undef
      let dropzone = Dropzone.forElement(".imageUploadDropzone");
      dropzone.removeAllFiles();

      for (let i = 0; i < params[key].length; i++) {
        let obj = { isMock: true, url: params[key][i] };

        dropzone.emit("addedfile", obj);
        dropzone.emit(
          "thumbnail",
          obj,
          process.env.VUE_APP_CMS_BACKEND_BASE_URL + params[key][i]
        );
        dropzone.emit("complete", obj);
        dropzone.files.push(obj);
      }
    } else if (value.key === "multiselectType") {
      $('input[name="list"]').val(params.list);
    } else if (value.key === "radioType") {
      inputList
        .find('[name="' + key + '"][value="' + params[key] + '"]')
        .attr("checked", true);
    } else if (value.key === "checkboxType") {
      inputList.find('[name="' + key + '"]').attr("checked", true);
    }
  }

  function setGroupInputValues(key, value, params, group) {
    let groupList = $("#" + group);
    let size = groupList.children().length;

    if (size < params[key].length) {
      let diff = params[key].length - size;
      for (let i = 0; i < diff; i++) {
        groupList.append(groupList.children().first().clone());
      }
    }

    for (let i = 0; i < params[key].length; i++) {
      let inputList = groupList.children().eq(i);
      if (value.key === "textType") {
        inputList.find('[name="' + key + '[]"]').val(params[key][i]);
      } else if (value.key === "textareaType") {
        inputList.find('[name="' + key + '[]"]').text(params[key][i]);
      } else if (value.key === "linkType") {
        inputList.find('[name="' + key + '[]"]').val(params[key][i]);
        inputList
          .find('[name="url_' + key + '[]"]')
          .val(params["url_" + key][i]);
      }
    }
  }

  // Methods visible outside the plugin.
  return {
    _init: _init,
    openModal: openModal,
    insertElem: insertHtml,
    deleteElem: deleteElem,
    hideEditPopup: hideEditPopup,
  };
};
