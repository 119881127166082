import Vue from "vue";
import Vuesax from "vuesax";
Vue.use(Vuesax);

import VueCroppie from "vue-croppie";
import "croppie/croppie.css"; // import the croppie css manually
Vue.use(VueCroppie);

// define global variable
Vue.prototype.$draggableValue = 1;

import JQuery from "jquery";
window.$ = JQuery;
window.jQuery = JQuery;

//fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUserSecret);

Vue.component("font-awesome-icon", FontAwesomeIcon);
// Require Froala Editor js file.
require("froala-editor/js/froala_editor.pkgd.min.js");

// Require Froala Editor css files.
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/css/froala_style.min.css");

// Require Froala Editor custom css file
require("@/assets/froala/css/custom.scss");
require("@/assets/froala/css/font.css");
require("dropzone/dist/dropzone.css");

// Require Froala Plugins
require("froala-editor/js/plugins/colors.min");
require("froala-editor/js/plugins/font_family.min");
require("froala-editor/js/plugins/font_size.min");
require("froala-editor/js/plugins/inline_class.min");
require("froala-editor/js/plugins/inline_style.min");
require("froala-editor/js/plugins/align.min");
require("froala-editor/js/plugins/lists.min");
require("froala-editor/js/plugins/paragraph_style.min");
require("froala-editor/js/plugins/paragraph_format.min");
require("froala-editor/js/plugins/line_height.min");
require("froala-editor/js/plugins/quote.min");
require("froala-editor/js/plugins/link.min");
require("froala-editor/js/plugins/emoticons.min");
require("froala-editor/js/plugins/special_characters.min");
require("froala-editor/js/plugins/fullscreen.min");
require("froala-editor/js/plugins/code_view.min");
require("froala-editor/js/plugins/help.min");
require("froala-editor/js/plugins/image.min");
require("froala-editor/js/plugins/draggable.min");

// Require Froala Third Parties
require("froala-editor/js/third_party/embedly.min");
require("froala-editor/js/third_party/font_awesome.min");
// require("@/views/edit/Component/tuiEditor.js");

// import ImageEditor from "tui-image-editor";
// Vue.use(ImageEditor);

// Require Froala Cassiel Plugins
let plugins = process.env.VUE_APP_FROALA_PLUGINS.split(",");
for (let i = 0; i < plugins.length; i++) {
  require("@/assets/froala/plugins/" + plugins[i]);
}
// Import and use Vue Froala lib.
import VueFroala from "vue-froala-wysiwyg";
Vue.use(VueFroala);

import vuedraggable from "vue-draggable";
Vue.use(vuedraggable);

// // If you don't need the styles, do not connect
// import 'sweetalert2/dist/sweetalert2.min.css';

import "tui-color-picker/dist/tui-color-picker.css";
import "tui-image-editor/dist/tui-image-editor.css";

//import tabs
import { TabsPlugin } from "bootstrap-vue";
Vue.use(TabsPlugin);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

//import tooltip
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

//import vue-select

import Select2 from "v-select2-component";

Vue.component("Select2", Select2);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

import App from "./App.vue";

import "vuesax/dist/vuesax.css"; //Vuesax styles
import "material-icons/iconfont/material-icons.css";
// Vuex Store
import store from "./store/store";

import axios from "axios";
const base = axios.create({
  baseURL: process.env.VUE_APP_CMS_BACKEND_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

import apiList from "./config/endpoints.json";
Vue.prototype.$http = base;
Vue.prototype.$apiList = apiList;

// Theme Configurations
import "../themeConfig.js";
// Perfectscrollbar
// import PerfectScrollbar from "vue2-perfect-scrollbar";
// import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
// Vue.use(PerfectScrollbar);

// Theme Configurations
// import 'prismjs'
// import 'prismjs/themes/prism.css'
// import VsPrism from './components/prism/VsPrism.vue';
// Vue.component(VsPrism.name, VsPrism);
// import VsSidebarGroup from './components/vs-sidebar-group/Sidebar-Group.vue';
// Vue.component(VsSidebarGroup.name, VsSidebarGroup);
// i18n
// import i18n from './i18n/i18n.js'
// Vue Router
import router from "./router";
Vue.config.productionTip = false;

import acl from "./acl.js";
Vue.prototype.$acl = acl;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
import "@/assets/scss/style.scss";
