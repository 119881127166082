let routes = [
  // ======================
  // Blank Layout
  // ======================
  {
    path: "",
    component: () => import("../layout/blank/Blank.vue"),
    // ======================
    // Theme routes / pages
    // ======================
    children: [
      {
        path: "/",
        redirect: "/login",
      },
      {
        path: "/login",
        name: "Login",
        index: 1,
        component: () => import("../views/auth/Login.vue"),
        meta: {
          acl: "public",
        },
      },
      {
        path: "/error404",
        name: "Error 404",
        index: 2,
        component: () => import("../views/error/404.vue"),
        meta: {
          acl: "*",
        },
      },
    ],
  },
  {
    // ======================
    // Full Layout
    // ======================
    path: "/",
    name: "dashboard",
    meta: {
      acl: "private",
    },
    component: () => import("../layout/full/MainContainer.vue"),
    // ======================
    // Theme routes / pages
    // ======================
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        index: 1.0,
        meta: {
          acl: "private",
          breadcrumb: [
            {
              title: "Dashboard",
              active: true,
            },
          ],
        },
        component: () => import("../views/dashboards/dashboard"),
      },
      {
        path: "/views/core/profileuser",
        name: "Profile Page",
        index: 1.0,
        meta: {
          acl: "private",
          breadcrumb: [
            {
              title: "Profile",
              active: true,
            },
          ],
        },
        props: true,
        component: () => import("../views/dashboards/dashboard"),
      },
      {
        path: "details/jamapp/notification",
        name: "Jam App Page",
        index: 1.0,
        meta: {
          acl: "private",
          breadcrumb: [
            {
              title: "jamapp",
              active: true,
            },
          ],
        },
        props: true,
        component: () => import("../views/custom/jamapp-notification-details"),
      },
      {
        path: "edit/contest/competitors",
        name: "Competitors Page",
        index: 1.0,
        meta: {
          acl: "private",
          breadcrumb: [
            {
              title: "competitors",
              active: true,
            },
          ],
        },
        props: true,
        component: () => import("../views/custom/contest-competitors"),
      },
      {
        path: "edit/pool/pool",
        name: "Pool Form Page",
        index: 1.0,
        meta: {
          acl: "private",
          breadcrumb: [
            {
              title: "pool",
              active: true,
            },
          ],
        },
        props: true,
        component: () => import("../views/custom/pool-form"),
      },
      {
        path: "edit/core/embeds",
        name: "Embed Widget Form Page",
        index: 1.0,
        meta: {
          acl: "private",
          breadcrumb: [
            {
              title: "Cassiel Embed",
              active: true,
            },
          ],
        },
        props: true,
        component: () => import("../views/custom/cassiel-embed-form"),
      },
      {
        path: "/views/:widgetName/:entityName",
        name: "List Page",
        index: 1.0,
        meta: {
          acl: "private",
          breadcrumb: [
            {
              title: "List",
              active: true,
            },
          ],
        },
        props: true,
        component: () => import("../views/list/List"),
      },
      {
        path: "/edit/:widgetName/:entityName",
        name: "Edit Page",
        index: 1.0,
        meta: {
          acl: "private",
          breadcrumb: [
            {
              title: "Edit",
              active: true,
            },
          ],
        },
        props: true,
        component: () => import("../views/edit/Edit"),
      },
      {
        path: "/details/:widgetName/:entityName/:cassielid?",
        name: "Details Page",
        index: 1.0,
        meta: {
          acl: "private",
          breadcrumb: [
            {
              title: "Details",
              active: true,
            },
          ],
        },
        props: true,
        component: () => import("../views/details/Details"),
      },
        {
            path: "/insights/:widgetName/:entityName/:cassielid?",
            name: "Details Page",
            index: 1.0,
            meta: {
                acl: "private",
                breadcrumb: [
                    {
                        title: "Quiz Insights",
                        active: true,
                    },
                ],
            },
            props: true,
            component: () => import("../views/insights/Insights"),
        },

      {
        path: "/custom/linker",
        name: "linker Page",
        index: 1.0,
        meta: {
          acl: "private",
          breadcrumb: [
            {
              title: "linker",
              active: true,
            },
          ],
        },
        props: true,
        component: () => import("../views/custom/linker"),
      },
      {
        path: "/jamapp/notification",
        name: "Jam App Page",
        index: 1.0,
        meta: {
          acl: "private",
          breadcrumb: [
            {
              title: "jamapp",
              active: true,
            },
          ],
        },
        props: true,
        component: () => import("../views/custom/jamapp"),
      },
    ],
  },
  // Redirect to 404 page, if no match found
  {
    path: "*",
    redirect: "/Error404",
  },
];

export default routes;
