export default {
    namespaced: true,
    state: {
        menuRecords: [],
        lastUpdated: null,

    },
    mutations: {

        //set or clear tokens
        setMenuRecords(state, value) {
            if (value) {
                state.menuRecords = value;
            } else {
                state.menuRecords = [];
            }

          state.lastUpdated = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
        }, //set or clear tokens


    },

    actions: {

        setMenuRecords({commit}, value) {
            commit('setMenuRecords', value);

        }

    },

    getters: {
        getMenu: state => {
            return state.menuRecords;
        },
        getLastUpdated: state => {
            return state.lastUpdated;
        },

    }
}
