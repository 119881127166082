import FroalaEditor from "froala-editor";
import $ from "jquery";
import cassielBase from "@/assets/froala/plugins/cassielBase";

const PLUGIN_NAME = "cassielTOC";

let current;
let editPopup = PLUGIN_NAME + ".edit";
let mainPopup = PLUGIN_NAME + ".main";
let removeCommand = PLUGIN_NAME + "Remove";
let updateCommand = PLUGIN_NAME + "Update";
let editButtons = PLUGIN_NAME + "EditBtns";

// Define popup template.
FroalaEditor.POPUP_TEMPLATES[mainPopup] = "[_CUSTOM_LAYER_]";
FroalaEditor.POPUP_TEMPLATES[editPopup] = "[_BUTTONS_]";

// Define popup buttons.
Object.assign(FroalaEditor.DEFAULTS, {
  [editButtons]: [removeCommand, updateCommand],
});

// The custom popup is defined inside a plugin (new or existing).
FroalaEditor.PLUGINS.cassielTOC = function (editor) {
  let cassielEmbed = false;

  function _init() {
    initPopup();
  }

  function initPopup() {
    // editor.events.$on(editor.$el, 'click', 'div.divTOC', editElem);
    editor.events.$on(editor.$el, "click", "div.divTOC", (e) => {
      let cb = new cassielBase(editor, editPopup, editButtons);
      cb.edit(e);
      current = $(e.currentTarget);
    });
  }

  function insertHtml(instance) {
    editor.html.insert(instance);
  }
  function updateHtml() {
    editor.selection.save();
    let htmlString = editor.html.get(true);
    let parser = new DOMParser();
    let doc = parser.parseFromString(htmlString, "text/html");
    let divTOC = doc.body.querySelector(".divTOC");
    if (divTOC) {
      let ol = divTOC.querySelector("ol");
      if (ol) {
        let li = ol.querySelectorAll("li");
        for (var i = 0; i < li.length; i++) {
          let a = li[i].querySelector("a");
          if (a) {
            var index = a.getAttribute("index");
            let heading = doc.body.querySelector("#" + index);
            if (heading) {
              let headingText = $(heading).text();
              if (headingText.match(/^[0-9]+[-\\.]/g)) {
                let number = headingText.match(/^[0-9]+[-\\.]/g)[0];
                headingText = headingText.substring(
                  number.length,
                  headingText.length
                );
              }
              a.innerHTML = headingText;
            } else li[i].remove();
          }
        }
      }
      editor.selection.save();
      editor.html.set(doc.documentElement.innerHTML);
    }
  }

  function hideEditPopup() {
    editor.popups.hide("cassielTOCEdit.popup");
  }

  function updateElem() {
    updateHtml();
    hideEditPopup();
  }
  function deleteElem() {
    var contentWrap = document.querySelector(".fr-wrapper");
    var headings = contentWrap.querySelectorAll("h2");
    for (var c = 0; c < headings.length; c++) {
      var id = $(headings[c]).attr("id");
      if (id != undefined)
        if (id.includes("toc_")) {
          $(headings[c]).removeAttr("id");
        }
    }
    cassielEmbed.remove();
    hideEditPopup();
  }

  function saveCaretPosition() {
      editor.selection.save();
  }

  // Methods visible outside the plugin.
  return {
      _init: _init,
      insertHtml: insertHtml,
      deleteElem: deleteElem,
      updateElem: updateElem,
      hideEditPopup: hideEditPopup,
      saveCaretPosition: saveCaretPosition,
  };
};

// Define an icon and command for the button that opens the custom popup.
FroalaEditor.DefineIcon(PLUGIN_NAME, {
  NAME: "list-alt",
  template: "fontawesome",
});
FroalaEditor.RegisterCommand(PLUGIN_NAME, {
  title: "Table Of Contents",
  icon: PLUGIN_NAME,
  undo: true,
  focus: true,
  refreshAfterCallback: true,
  // popup: true,
  plugin: PLUGIN_NAME,
  callback: function () {
      this.cassielTOC.saveCaretPosition();
      displayOptions(this.cassielTOC);
  },
});

const displayOptions = (cassielTOC) => {
    const divContainer = document.createElement('div');
    divContainer.className = "lightboxContainer";
    divContainer.id = "lightbox";

    const divBody = document.createElement('div');
    divBody.className = "lightboxBody";

    const label = document.createElement('div');
    label.innerHTML = "Titles to include";
    label.className = "lightboxHeader";
    divBody.appendChild(label);

    for(let i = 1; i <= 4; i++) {
        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.name = 'header';
        checkbox.id = 'h'+ i;
        checkbox.value = 'h'+ i;

        const label = document.createElement('label')
        label.htmlFor = 'h'+ i;
        label.appendChild(document.createTextNode('H'+ i));

        divBody.appendChild(checkbox);
        divBody.appendChild(label);
    }

    const br = document.createElement('br');
    divBody.appendChild(br);

    const submit = document.createElement('input');
    submit.type = 'button';
    submit.className = 'button';
    submit.name = 'continue';
    submit.value = 'Continue';
    submit.onclick = ()=>handleClick(cassielTOC);
    divBody.appendChild(submit);

    const cancel = document.createElement('input');
    cancel.type = 'button';
    cancel.className = 'btn btn-transparent btn-left-border btn-rounded';
    cancel.name = 'cancel';
    cancel.value = 'Cancel';
    cancel.onclick = ()=>handleCancel();
    divBody.appendChild(cancel);

    divContainer.appendChild(divBody);
    document.body.appendChild(divContainer);
}

const handleCancel = () => {
    var elem = document.getElementById("lightbox");
    elem.remove();
}

const handleClick = (cassielTOC) => {
    let headers = []
    let checkboxes = document.querySelectorAll('input[name=header][type=checkbox]:checked')

    for (let i = 0; i < checkboxes.length; i++) {
        headers.push(checkboxes[i].value)
    }
    if(headers.length > 0) {
        BuildTableOfContent(cassielTOC, headers.toString());
        handleCancel();
    }
}

FroalaEditor.DefineIcon(updateCommand, {
  NAME: "refresh",
  template: "fontawesome",
});
FroalaEditor.RegisterCommand(updateCommand, {
  title: "Update",
  undo: true,
  focus: false,
  refreshAfterCallback: true,
  callback: function () {
    this.cassielTOC.updateElem();
  },
});

FroalaEditor.DefineIcon(removeCommand, { NAME: "trash", SVG_KEY: "remove" });
FroalaEditor.RegisterCommand(removeCommand, {
  title: "Delete",
  undo: true,
  focus: false,
  callback: function () {
    let cb = new cassielBase(this, editPopup);
    cb.current = current;
    cb.deleteCurrent();
  },
});

const BuildTableOfContent = (cassielTOC, headers) => {
    tableOfContents(".fr-wrapper", cassielTOC, {
        heading: "قائمة المحتويات", // Change the headings
        listType: "ol", // Change the list type
        levels: headers, // Change the levels used
    });
}

var tableOfContents = function (content, target, options) {
  var i = 0;
  // Get content
  var contentWrap = document.querySelector(content);
  var toc = target;
  if (!contentWrap) return;

  // Settings & Defaults
  var defaults = {
    levels: "h1, h2",
    heading: "Table of Contents",
    headingLevel: "h2",
    listType: "ul",
  };
  var settings = {};

  // Placeholder for headings
  var headings;

  /**
   * Merge user options into defaults
   * @param  {Object} obj The user options
   */
  var merge = function (obj) {
    for (var key in defaults) {
      if (Object.prototype.hasOwnProperty.call(defaults, key)) {
        settings[key] = Object.prototype.hasOwnProperty.call(obj, key)
          ? obj[key]
          : defaults[key];
      }
    }
  };
  /**
   * Create an ID for a heading if one does not exist
   * @param  {Node} heading The heading element
   */
  var createID = function (heading) {
    // if (heading.id.length) return;
    heading.id = "toc_" + i;
    i++;
    // heading.id = 'toc_' + heading.textContent.replace(/[^A-Za-z0-9]/g, '-');
  };

  /**
   * Get the HTML to indent a list a specific number of levels
   * @param  {Integer} count The number of times to indent the list
   * @return {String}        The HTML
   */
  var getIndent = function (count) {
    var html = "";
    for (var i = 0; i < count; i++) {
      html += "<" + settings.listType + ">";
    }
    return html;
  };

  /**
   * Get the HTML to close an indented list a specific number of levels
   * @param  {Integer} count The number of times to "outdent" the list
   * @return {String}        The HTML
   */
  var getOutdent = function (count) {
    var html = "";
    for (var i = 0; i < count; i++) {
      html += "</" + settings.listType + "></li>";
    }
    return html;
  };

  /**
   * Get the HTML string to start a new list of headings
   * @param  {Integer} diff  The number of levels in or out from the current level the list is
   * @param  {Integer} index The index of the heading in the "headings" NodeList
   * @return {String}        The HTML
   */
  var getStartingHTML = function (diff, index) {
    // If indenting
    if (diff > 0) {
      return getIndent(diff);
    }

    // If outdenting
    if (diff < 0) {
      return getOutdent(Math.abs(diff));
    }

    // If it's not the first item and there's no difference
    if (index && !diff) {
      return "</li>";
    }

    return "";
  };

  /**
   * Inject the table of contents into the DOM
   */
  var injectTOC = function () {
    // Track the current heading level
    var level = headings[0].tagName.slice(1);
    var startingLevel = level;

    // Cache the number of headings
    var len = headings.length - 1;

    // Inject the HTML into the DOM

    let tocHtml =
      '<div class="divTOC"><' +
      settings.headingLevel +
      ' class="titleTOC" >' +
      settings.heading +
      "</" +
      settings.headingLevel +
      ">" +
      "<" +
      settings.listType +
      ">" +
      Array.prototype.map
        .call(headings, function (heading, index) {
          // Check the heading level vs. the current list
          var currentLevel = heading.tagName.slice(1);
          var levelDifference = currentLevel - level;
          level = currentLevel;
          var html = getStartingHTML(levelDifference, index);
          if ($(heading).text()) {
            let headingText = $(heading).text();
            if (headingText.match(/^[0-9]+[-\\.]/g)) {
              let number = headingText.match(/^[0-9]+[-\\.]/g)[0];
              headingText = headingText.substring(
                number.length,
                headingText.length
              );
            }
            if (heading.innerHTML.trim().length) {
              // Add an ID if one is missing
              createID(heading);
              // Generate the HTML
              html +=
                "<li>" +
                "<a onclick=\"scrollDiv('" +
                heading.id +
                '\')" index="' +
                heading.id +
                '" style="cursor: pointer; font-family: ArabicBody " >' +
                headingText +
                "</a>";
            }
          }
          // If the last item, close it all out
          if (index === len) {
            html += getOutdent(Math.abs(startingLevel - currentLevel));
          }

          return html;
        })
        .join("") +
      "</" +
      settings.listType +
      ">";
    tocHtml += "</div>";
    toc.insertHtml(tocHtml);
  };

  /**
   * Initialize the script
   */
  var init = function () {
    // Merge any user settings into the defaults
    merge(options || {});

    // Get the headings
    // If none are found, don't render a list
    headings = contentWrap.querySelectorAll(settings.levels);
    if (!headings.length) return;

    // Inject the table of contents
    injectTOC();
  };
  // Initialize the script
  init();
};
