import $ from "jquery";

export default class cassielBase {

    current = null;

    constructor(editor = null, editPopup= null, editButtons= null) {
        this.editor = editor;
        this.editPopup = editPopup;
        this.editButtons = editButtons
    }

    edit(event) {
        if (event && this.editor.edit.isDisabled()) {
            event.stopPropagation();
            event.preventDefault();

            return false;
        }

        if (event) {
            event.stopPropagation();
            event.preventDefault();

            this.current = $(event.currentTarget);

            this.selectElement();
            this.showEditPopup();
        }
    }

    selectElement() {

        if ($(this.current)) {

            this.current[0].style.border = '1px blue solid';
            this.editor.selection.clear();
            let range = this.editor.doc.createRange();
            range.selectNode(this.current.get(0));

            // Collapse range in IE.
            if (this.editor.browser.msie) {
                range.collapse(true);
            }

            let selection = this.editor.selection.get();
            selection.addRange(range);
            this.editor.events.enableBlur();

        }
    }

    showEditPopup() {

        let popup = this.editor.popups.get(this.editPopup);

        if (!popup) popup = this.initEditPopup();

        if (popup) {

            this.editor.popups.setContainer(this.editPopup, this.editor.$sc);
            this.editor.popups.refresh(this.editPopup);
            let left = this.current.offset().left + this.current.outerWidth() / 2;
            let top = this.current.offset().top + this.current.outerHeight();

            this.editor.popups.show(this.editPopup, left, top, this.current.outerHeight());
        }
    }

    initEditPopup() {

        let buttons = '';

        if (this.editor.opts[this.editButtons].length >= 1) {

            buttons += '<div class="fr-buttons">';
            buttons += this.editor.button.buildList(this.editor.opts[this.editButtons]);
            buttons += '</div>';
        }

        let template = {buttons: buttons};

        return this.editor.popups.create(this.editPopup, template);
    }

    deleteCurrent() {
        let x = confirm('Do you want to delete?');
        if (!x) return false;
        this.current.remove();
        this.editor.popups.hide(this.editPopup);
    }

    insertHtml(flag, html, current) {
        if (flag === 1) {
            let newElement = document.createElement('p');
            newElement.innerHTML = html;
            current[0].parentNode.replaceWith(newElement);
        }
        if (flag === 2) {
            this.editor.selection.restore();
            this.editor.events.focus();
            this.editor.html.insert(html);
        }

    }
}