import FroalaEditor from "froala-editor";
import Dropzone from "dropzone";

import $ from "jquery";
require("jquery-ui");
require("jquery-ui/ui/widgets/sortable");

import apiList from "/src/config/endpoints.json";
import cassielBase from "@/assets/froala/plugins/cassielBase";
import cassielBaseDz from "@/assets/froala/plugins/cassielBaseDz";

let base = process.env.VUE_APP_CMS_BACKEND_BASE_URL_FROALA;
let baseAdmin = process.env.VUE_APP_CMS_BACKEND_BASE_URL;

const PLUGIN_NAME = "cassielMultiGallery";

let current;
let editPopup = PLUGIN_NAME + ".edit";
let editButtons = PLUGIN_NAME + "EditButtons";
let editCommand = PLUGIN_NAME + "Edit";
let removeCommand = PLUGIN_NAME + "Remove";
let component = "multigallery";

FroalaEditor.DefineIconTemplate(
  "fontcassiel",
  '<i class="icon icon-[NAME]"></i>'
);
FroalaEditor.DefineIcon(PLUGIN_NAME, {
  NAME: "gallery",
  template: "fontcassiel",
});
FroalaEditor.RegisterCommand(PLUGIN_NAME, {
  title: "Multi Gallery",
  icon: PLUGIN_NAME,
  refreshAfterCallback: true,
  callback: function () {
    this[PLUGIN_NAME].openModal();
  },
});

FroalaEditor.POPUP_TEMPLATES[editPopup] = "[_BUTTONS_]";

Object.assign(FroalaEditor.DEFAULTS, {
  [editButtons]: [editCommand, removeCommand],
  cassielSoloImagesAPI: apiList.uploadFileApi,
});

FroalaEditor.DefineIcon(removeCommand, { NAME: "trash", SVG_KEY: "remove" });
FroalaEditor.RegisterCommand(removeCommand, {
  title: "Delete",
  undo: true,
  focus: false,
  callback: function () {
    let cb = new cassielBase(this, editPopup);
    cb.current = current;
    cb.deleteCurrent();
  },
});

FroalaEditor.DefineIcon(editCommand, { NAME: "edit", SVG_KEY: "edit" });
FroalaEditor.RegisterCommand(editCommand, {
  title: "Edit",
  undo: true,
  focus: false,
  callback: function () {
    this[PLUGIN_NAME].showPopup();
  },
});

FroalaEditor.PLUGINS[PLUGIN_NAME] = function (editor) {
  let dropzone;

  let flag = 0;

  let modal;

  // let rand = Math.floor(Math.random() * 101);
  let modalId = PLUGIN_NAME + "Modal" + editor.id;
  let dropzoneClass = PLUGIN_NAME + "Dropzone" + editor.id;
  let insertClass = PLUGIN_NAME + "Insert" + editor.id;

  function _init() {
    initPopup();
    $("." + insertClass).click(function () {
      insertHtml();
    });

    editor.events.$on(
      editor.$el,
      "click",
      'cassielembed[data-component="' + component + '"]',
      (e) => {
        let cb = new cassielBase(editor, editPopup, editButtons);
        cb.edit(e);

        current = $(e.currentTarget);
      }
    );

    $(document).on(
      "change",
      '[name="caption"], [name="alt"], [name="title"]',
      function () {
        let dz = new cassielBaseDz(dropzone);
        let element = $(this).closest(".dz-image-preview");
        dz.updateFile(element[0]);
      }
    );
  }

  function initPopup() {
    if (!modal) {
      let head =
        "<h4> Multi Gallery </h4>" +
        '<button class="btn btn-primary ' +
        insertClass +
        '" ' +
        '       style="float:right; margin-right:50px;margin-top: 12px;">' +
        "   Insert" +
        "</button>";
      let body =
        '<div class="dropzone fr-image-list ' +
        dropzoneClass +
        '" ' +
        'style="min-height: 300px"></div>';

      let modalHash = editor.modals.create(modalId, head, body);
      modal = modalHash.$modal;

      let dropzoneElem = $("." + dropzoneClass);
      if (dropzoneElem.length === 0) {
        return;
      }

      let dz = new cassielBaseDz();
      let headers = {};
      if (typeof Storage !== "undefined") {
        headers["X-AUTH-TOKEN"] = localStorage
          .getItem("token")
          .replaceAll('"', "");
      }
      dropzoneElem.sortable();
      dropzone = new Dropzone("." + dropzoneClass, {
        url: baseAdmin + editor.opts.cassielSoloImagesAPI,
        headers: headers,
        autoProcessQueue: true,
        parallelUploads: 1,
        thumbnailWidth: null,
        addRemoveLinks: true,
        previewTemplate:
          "" +
          '<div class="dz-preview dz-file-preview">' +
          '   <div class="dz-image dz-image-custom"><img data-dz-thumbnail /></div>' +
          '   <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' +
          '   <div class="dz-error-message"><span data-dz-errormessage></span></div>' +
          '   <div class="dz-images-input">' +
          '       <label class="text-monospace">Title</label>' +
          '       <input type="text" ' +
          '               class="form-control border-bottom-teal" ' +
          '               name="title" ' +
          '               placeholder="Title" disabled>' +
          '       <label class="text-monospace">Caption</label>' +
          '       <textarea type="text" ' +
          '               class="form-control border-bottom-teal" ' +
          '               name="caption" ' +
          '               placeholder="Caption" disabled></textarea>' +
          '       <input type="hidden" name="image" >' +
          "   </div>" +
          "</div>",
        init: function () {
          this.on("addedfile", function (file) {
            dz.addFile(file);
            enableInputs(file);
          });
        },
        success: function (file, response) {
          dz.addFile(file, response.filePath);
          enableInputs(file);

          file.previewElement.querySelector(".dz-progress").remove();
        },
        removedfile: function (file) {
          file.previewElement.remove();
        },
      });
    }
  }

  function showPopup() {
    flag = 1;

    let list = JSON.parse(current[0].getAttribute("data-params"));
    let images = [];

    if (list.urls !== undefined) {
      for (const prop in list.urls) {
        images.push({ url: list.urls[prop] });
      }
    }

    let dz = new cassielBaseDz(dropzone);
    dz.loadImages(images, base);

    editor.modals.show(modalId);
  }

  function insertHtml() {
    let dz = new cassielBaseDz(dropzone);
    let list = dz.getImages();

    let urls = [];
    let captions = [];
    let titles = [];

    for (const prop in list) {
      urls.push(list[prop].url);
      captions.push(list[prop].caption);
      titles.push(list[prop].title);
    }

    let params = { urls: urls, captions: captions, names: titles };

    let html =
      '<cassielembed contenteditable="false" ' +
      'class="fr-draggable" ' +
      'data-component="' +
      component +
      '" ' +
      "data-params='" +
      JSON.stringify(params) +
      '\' data-widget="froalaextras">' +
      "&nbsp;" +
      "<code>{{ CASSIELEMBED MULTI GALLERY }}</>" +
      "</cassielembed>";

    let cb = new cassielBase(editor);
    cb.insertHtml(flag, html, current);
    flag = 0;

    editor.modals.hide(modalId);
    $(editor.selection.element())
      .closest(".form-group")
      .find("textarea")
      .val(editor.el.innerHTML);
  }

  function openModal() {
    flag = 2;
    dropzone.removeAllFiles();
    editor.modals.show(modalId);
  }

  function enableInputs(file) {
    $(file.previewElement.querySelector("button")).removeAttr("disabled");
    $(file.previewElement.querySelector('input[name="title"]')).removeAttr(
      "disabled"
    );
    $(file.previewElement.querySelector('textarea[name="caption"]')).removeAttr(
      "disabled"
    );
  }

  return {
    _init: _init,
    showPopup: showPopup,
    openModal: openModal,
  };
};
