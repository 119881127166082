export default {
    namespaced: true,
    state: {
        token: null,
        userName: null,
        roles: null,
        refreshToken: null,
        photo: null
    },
    mutations: {

        //set or clear tokens
        setToken(state, value) {
            if (value && value.token) {
                state.token = value.token;
                localStorage.setItem('token', JSON.stringify(state.token));
            } else {
                state.token = null;
                localStorage.removeItem('token');
            }
        }, //set or clear tokens
        setRefreshToken(state, value) {
            if (value && value.refreshToken) {
                state.refreshToken = value.refreshToken;
                localStorage.setItem('refreshToken', JSON.stringify(state.refreshToken));
            } else {
                state.refreshToken = null;
                localStorage.removeItem('refreshToken');
            }
        },
        //set or clear tokens
        setUserName(state, value) {
            if (value && value.token) {
                state.userName = value.userName;
                localStorage.setItem('userName', JSON.stringify(state.userName));

                state.photo = value.photo;
                localStorage.setItem('photo', JSON.stringify(state.photo));
            } else {
                state.userName = null;
                localStorage.removeItem('userName');
                state.photo = null;
                localStorage.removeItem('photo');
            }
        },
        setPhoto(state, value) {
            if (value && value.token) {
                console.log(value)
                state.photo = value.photo;
                localStorage.setItem('photo', JSON.stringify(state.photo));
            } else {
                state.photo = null;
                localStorage.removeItem('photo');
            }
        },
        //set or clear acl
        setRoles(state, value) {
            if (value) {
                let roles = JSON.parse(JSON.stringify(value.roles));
                state.roles = roles;
                localStorage.setItem('roles', JSON.stringify(roles));
            }
        },
        clearRoles(state) {
            state.roles = null;
            localStorage.removeItem('roles');
        },


    },

    actions: {

        setRoles({commit}, value) {
            commit('setRoles', value);
        },
        setUser({commit}, value) {
            commit('setUserName', value);
            commit('setRoles', value);
        },
        setPhoto({commit}, value) {
            commit('setPhoto', value);
        },
        clearUser({commit}) {
            commit('setUserName', null);
            commit('clearRoles', null);
        },

        setTokens({commit}, value) {
            commit('setToken', value);
            commit('setRefreshToken', value);
        },

        clear({commit}) {
            commit('setToken', null);
            commit('setRefreshToken', null);
            commit('setUserName', null);
            commit('setRoles', null);
        }
    },

    getters: {
        getToken: state => {
            if (localStorage.getItem('token')) {
                state.token = JSON.parse(localStorage.getItem('token'));
            } else if (state.token) {
                localStorage.setItem('token', JSON.stringify(state.token));
            }
            return state.token;
        },
        getRefreshToken: state => {
            if (localStorage.getItem('refreshToken')) {
                state.refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
            } else if (state.refreshToken) {
                localStorage.setItem('refreshToken', JSON.stringify(state.refreshToken));
            }
            return state.refreshToken;
        },

        getUser: (state, getters) => {
            const token = getters['getToken'];
            if (!token) {
                return null;
            }

            if (localStorage.getItem('userName')) {
                state.userName = JSON.parse(localStorage.getItem('userName'));
            } else if (state.userName) {
                localStorage.setItem('userName', JSON.stringify(state.userName));
            }

            return state.userName;
        },
        getPhoto: (state, getters) => {
            const token = getters['getToken'];
            if (!token) {
                return null;
            }

            if (localStorage.getItem('photo')) {
                state.photo = JSON.parse(localStorage.getItem('photo'));
            } else if (state.photo) {
                localStorage.setItem('photo', JSON.stringify(state.photo));
            }
            return state.photo;
        },

        getRoles: (state, getters) => {
            const token = getters['getToken'];
            if (!token) {
                return null;
            }
            if (localStorage.getItem('roles')) {
                state.roles = JSON.parse(localStorage.getItem('roles'));
            } else if (state.roles) {
                localStorage.setItem('roles', JSON.stringify(state.roles));
            }
            return state.roles;
        }
    }
}
