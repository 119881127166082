import FroalaEditor from 'froala-editor';
import $ from 'jquery'
import cassielBase from "@/assets/froala/plugins/cassielBase";

const PLUGIN_NAME = 'cassielURLEmbed'

let current

let mainPopup = PLUGIN_NAME + '.main'
let editPopup = PLUGIN_NAME + '.edit'

let mainButtons = PLUGIN_NAME + 'MainButtons'
let editButtons = PLUGIN_NAME + 'EditButtons'

let closeCommand = PLUGIN_NAME + 'Close'
let insertCommand = PLUGIN_NAME + 'Insert'
let removeCommand = PLUGIN_NAME + 'Remove'

let component = 'cassielEmbedURL'


function getScript(source, callback) {
    let script = document.createElement('script');
    let prior = document.getElementsByTagName('script')[0];
    script.async = 1;

    script.onload = script.onreadystatechange = function (_, isAbort) {
        if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
            script.onload = script.onreadystatechange = null;
            script = undefined;

            if (!isAbort && callback) setTimeout(callback, 0);
        }
    };

    script.src = source;
    prior.parentNode.insertBefore(script, prior);
}

getScript("https://static.apester.com/js/sdk/latest/apester-sdk.js", function () {
    let styles = "" +
        ".embedurl::after {" +
        "position: absolute;" +
        "content: '';" +
        "z-index: 1;" +
        "top: 0;" +
        "left: 0;" +
        "right: 0;" +
        "bottom: 0;" +
        "cursor: pointer;" +
        "display: block;" +
        "background: rgba(0, 0, 0, 0);" +
        "}";

    let styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet)
});
// Define popup template.
FroalaEditor.POPUP_TEMPLATES[mainPopup] = '[_BUTTONS_][_CUSTOM_LAYER_]';
FroalaEditor.POPUP_TEMPLATES[editPopup] = '[_BUTTONS_]';


// Define popup buttons.
Object.assign(FroalaEditor.DEFAULTS, {
    [mainButtons]: [closeCommand, '|'],
    [editButtons]: [removeCommand],
});


FroalaEditor.DefineIconTemplate('fontawesome', '<i class="fa fa-[NAME]"></i>');
FroalaEditor.DefineIcon(PLUGIN_NAME, {NAME: 'arrow-down', template: 'fontawesome'});
FroalaEditor.RegisterCommand(PLUGIN_NAME, {
    title: 'Embed URL',
    icon: PLUGIN_NAME,
    undo: true,
    focus: true,
    popup: true,
    refreshAfterCallback: true,
    plugin: PLUGIN_NAME,
    callback: function () {
        if (!this.popups.isVisible(mainPopup)) {
            this[PLUGIN_NAME].showPopup();
        } else {
            if (this.$el.find('.fr-marker')) {
                this.events.disableBlur();
                this.selection.restore();
            }
            this.popups.hide(mainPopup);
        }
    }
});

FroalaEditor.DefineIcon(closeCommand, {NAME: 'times', SVG_KEY: 'close'});
FroalaEditor.RegisterCommand(closeCommand, {
    title: 'Close',
    undo: true,
    focus: true,
    refreshAfterCallback: true,
    callback: function () {
        this[PLUGIN_NAME].hidePopup();
    }
});

FroalaEditor.RegisterCommand(insertCommand, {
    title: 'Insert',
    undo: true,
    focus: true,
    refreshAfterCallback: true,
    callback: function () {
        let embedFactory = new EmbedFactory();

        let url = this.popups.get(mainPopup).find(".fr-cassielURLEmbed-layer input").val();
        let instance = embedFactory.createEmbed({'url': url});
        if (instance === null) {
            this[PLUGIN_NAME].refreshPopup();
        } else {
            this[PLUGIN_NAME].insertHtml(instance);
            this[PLUGIN_NAME].refreshPopup();

        }
    }
});

FroalaEditor.DefineIcon(removeCommand, {NAME: 'trash', SVG_KEY: 'remove'});
FroalaEditor.RegisterCommand(removeCommand, {
    title: 'Delete',
    undo: true,
    focus: true,
    refreshAfterCallback: true,
    callback: function () {
        let cb = new cassielBase(this, editPopup)
        cb.current = current
        cb.deleteCurrent()

        let el = $(current);
        let top = $(window).scrollTop() - el.outerHeight();
        $(window).scrollTop(top);
    }
});

FroalaEditor.PLUGINS[PLUGIN_NAME] = function (editor) {

    function _init() {
        initPopup();

        editor.events.$on(editor.$el, 'click', '.' + component, (e) => {
            let cb = new cassielBase(editor, editPopup, editButtons)
            cb.edit(e)

            current = $(e.currentTarget)
        });
    }

    function initPopup() {

        let buttons = '';

        if (editor.opts[mainButtons].length > 1) {
            buttons += '<div class="fr-buttons">';
            buttons += editor.button.buildList(editor.opts[mainButtons]);
            buttons += '</div>';
        }

        let template = {
            buttons: buttons,
            custom_layer: '<div class="fr-cassielURLEmbed-layer fr-active fr-layer" id="fr-cassielURLEmbed-layer-' + editor.id + '"><' +
                'div class="fr-input-line">' +
                '<input id="fr-cassielURLEmbed-layer-text-' + editor.id + '" type="text" placeholder="' + editor.language.translate("Paste in a URL to embed") + '" tabIndex="1" aria-required="true">' +
                '</div>' +
                '<div class="fr-action-buttons">' +
                '<button type="button" class="fr-command fr-submit" data-cmd="' + insertCommand + '" tabIndex="2" role="button">' + editor.language.translate("Insert") + "</button>" +
                "</div>" +
                "</div>"
        };

        return editor.popups.create(mainPopup, template);
    }

    function showPopup() {

        let popup = editor.popups.get(mainPopup);

        if (!popup) initPopup();

        editor.popups.setContainer(mainPopup, editor.$tb);

        let btn = editor.$tb.find('.fr-command[data-cmd="cassielURLEmbed"]');
        let left = btn.offset().left + btn.outerWidth() / 2;
        let top = btn.offset().top + (editor.opts.toolbarBottom ? 10 : btn.outerHeight() - 10);

        editor.popups.show(mainPopup, left, top, btn.outerHeight());
    }

    function insertHtml(instance) {
        editor.html.insert(instance.embed);
    }

    function hidePopup() {
        editor.popups.hide(mainPopup);
    }

    function refreshPopup() {
        editor.popups.get(mainPopup).find(".fr-cassielURLEmbed-layer input").val("");
        editor.popups.hide(mainPopup);
    }

    return {
        _init: _init,
        showPopup: showPopup,
        hidePopup: hidePopup,
        refreshPopup: refreshPopup,
        insertHtml: insertHtml,
    }
};


function Iframe(url) {
    this.url = url;
    this.embed = '<iframe src="' + this.url + '"></iframe>';
}

function Apester(url) {
    this.pattern = new RegExp('^(?:https?:\\/\\/)?' + // protocol
        '(?:discover.apester.com)' + // domain name
        '(?:\\/([-a-z\\d%_.~+]*))*' + // path
        '(?:\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(?:\\#[-a-z\\d_]*)?$', 'i');
    this.script = 'https://static.apester.com/js/sdk/latest/apester-sdk.js';

    this.url = url;

    this.valid = this.pattern.test(this.url);

    this.id = Math.floor((Math.random() * 1000) + 1);
    this.embed = '<div id="' + this.id + '" class="cassielEmbedURL">' +
        '<div class="apester-media" data-media-id="' + this.id + '" height="478">' +
        '<code>{ Apster Embed }</code>' +
        '</div>' +
        '<script async src="' + this.script + '"></script>' +
        '</div>';
}

function removeTrailingSlash(str){
    return str.replace(/\/+$/, '');
}

function Instagram(url) {
    this.pattern = new RegExp('^(?:https?:\\/\\/).*.' + // protocol
        '(?:instagram.com).*', 'i');
    let base = url.split("/?");

    this.url = removeTrailingSlash(base[0]);
    this.valid = this.pattern.test(this.url);
    this.id = Math.floor((Math.random() * 1000) + 1);
    this.embed = '<div class="cassielEmbedURL" id="' + this.id + '">' +
        '<iframe ' +
        'class="instagram-media instagram-media-rendered" ' +
        'src="' + this.url + '/embed/captioned/?cr=1&v=12&wp=540&rd=https%3A%2F%2Fadmin.jambeta.sprd.cloud" ' +
        'allowtransparency="true" ' +
        'allowfullscreen="true"  ' +
        'scrolling="no" ' +
        'height="832" ' +
        'data-instgrm-payload-id="instagram-media-payload-0" ' +
        'style="background: white; max-width: 540px; width: calc(100% - 2px); border-radius: 3px; border: 1px solid rgb(219, 219, 219); box-shadow: none; display: block; margin: 0 0 12px; min-width: 326px; padding: 0;">' +
        '</iframe> ' +
        '<script src="//www.instagram.com/embed.js"></script>' +
        '</div>';
}

function EmbedFactory() {
}

EmbedFactory.prototype.embedClass = Iframe;
EmbedFactory.prototype.createEmbed = function (options) {
    this.url = options.url;
    this.pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    if (!this.pattern.test(this.url)) {
        return null;
    }

    this.embedList = [Apester, Instagram];
    this.embedList.forEach(element => {
        let instance;
        instance = new element(this.url);
        if (instance.valid) {
            this.embedClass = element;
        }
    });

    return new this.embedClass(this.url);

};