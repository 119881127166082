import Vue from "vue";
import Vuesax from "vuesax";

Vue.use(Vuesax, {
  theme: {
    colors: {
      transparent: "transparent",
      primary: "#586BB2",
      success: "#36bea6",
      danger: "#E93E3B",
      warning: "#ffbc34",
      dark: "#212529",
      teal: "#008080",
    },
  },
});

// CONFIGS
const themeConfig = {
  theme: "light", // options: dark and light theme
  logotitle: "CASSIEL",
  sidebarCollapsed: false, // options: true (mini-sidebar), false(default)
  topbarColor: "#FFF", // options: anycolor you can use
  themeColor: "#212121", // options: anycolor you can use
};

export default themeConfig;
