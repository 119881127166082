import FroalaEditor from "froala-editor";

import $ from "jquery";
require("datatables");
require("datatables/media/css/jquery.dataTables.min.css");

import apiList from "/src/config/endpoints.json";
// let base = process.env.VUE_APP_CMS_BACKEND_BASE_URL_FROALA;
let baseAdmin = process.env.VUE_APP_CMS_BACKEND_BASE_URL;

import cassielBase from "@/assets/froala/plugins/cassielBase";

const PLUGIN_NAME = "cassielVodEmbed";

let current;

let editPopup = PLUGIN_NAME + ".edit";
let editButtons = PLUGIN_NAME + "EditButtons";
let removeCommand = PLUGIN_NAME + "Remove";
let component = "galleryvod";

FroalaEditor.POPUP_TEMPLATES[editPopup] = "[_BUTTONS_]";

FroalaEditor.DefineIconTemplate(
  "fontcassiel",
  '<i class="icon icon-[NAME]"></i>'
);
FroalaEditor.DefineIcon(PLUGIN_NAME, {
  NAME: "video",
  template: "fontcassiel",
});
FroalaEditor.RegisterCommand(PLUGIN_NAME, {
  title: "Insert Video From VOD Gallery",
  icon: PLUGIN_NAME,
  refreshAfterCallback: true,
  callback: function () {
    this[PLUGIN_NAME].openModal();
  },
});

Object.assign(FroalaEditor.DEFAULTS, {
  cassielVodEmbedApiURL: apiList.froalaVideosList,
  [editButtons]: [removeCommand],
});

// Define Delete button.
FroalaEditor.DefineIcon(removeCommand, { NAME: "trash", SVG_KEY: "remove" });
FroalaEditor.RegisterCommand(removeCommand, {
  title: "Delete",
  undo: true,
  focus: true,
  refreshAfterCallback: true,
  callback: function () {
    let cb = new cassielBase(this, editPopup);
    cb.current = current;
    cb.deleteCurrent();
  },
});

FroalaEditor.PLUGINS[PLUGIN_NAME] = function (editor) {
  let modal;

  // let rand = Math.floor(Math.random() * 101);
  let modalId = PLUGIN_NAME + "Modal" + editor.id;
  let insertClass = PLUGIN_NAME + "Insert" + editor.id;

  function _init() {
    initModal();

    editor.events.$on(
      editor.$el,
      "click",
      'cassielembed[data-component="' + component + '"]',
      (e) => {
        let cb = new cassielBase(editor, editPopup, editButtons);
        cb.edit(e);

        current = $(e.currentTarget);
      }
    );

    $(document).on(
      "click",
      "button[data-name='" + insertClass + "']",
      insertHTML
    );

    $("input[type='search']").on("keydown", function (e) {
      e.stopPropagation();
    });
  }

  function initModal() {
    if (!modal) {
      let head = '<h4 data-text="true">VOD Gallery</h4>';
      let body =
        '<table id="' +
        modalId +
        '" class="table table-striped " style="width:100%">' +
        "<thead>" +
        "<tr>" +
        "<th>Id</th>" +
        "<th>Title</th>" +
        "<th>Section</th>" +
        "<th>Insert Video</th>" +
        "</tr>" +
        "</thead>" +
        "<tbody>" +
        "</tbody>" +
        "</table>";

      let headers = {};
      if (typeof Storage !== "undefined") {
        headers["X-AUTH-TOKEN"] = localStorage
          .getItem("token")
          .replaceAll('"', "");
      }

      let modalHash = editor.modals.create(modalId, head, body);
      modal = modalHash.$modal;
      $.fn.dataTable.ext.errMode = "throw";
      $("#" + modalId).DataTable({
        retrieve: false,

        columns: [
          { data: "0", name: "vod.id" },
          { data: "1", name: "vod.title" },
          { data: "2", name: "section.name" },
          { data: "4", name: "vod.dailymotionId" },
        ],
        ajax: {
          type: "GET",
          url: baseAdmin + editor.opts.cassielVodEmbedApiURL,
          headers: headers,
        },
        processing: true,
        serverSide: true,
        searching: true,
        searchDelay: 1000,
        order: [[0, "desc"]],
        createdRow: function (row) {
          let link = $(row).children("td")[3].innerText;
          let id = $(row).children("td")[0].innerText;
          $(row).children("td")[3].innerHTML =
            "" +
            '<button class="btn btn-primary" ' +
            'data-link="' +
            link +
            '"  ' +
            'data-id="' +
            id +
            '"  ' +
            'data-name="' +
            insertClass +
            '">' +
            "Add to Editor" +
            "</button>";
        },
      });
    }
  }

  function insertHTML() {
    editor.selection.restore();
    editor.events.focus();

    let html =
      "<cassielembed " +
      'contenteditable="false" ' +
      'class="fr-draggable" ' +
      'data-component="' +
      component +
      '" ' +
      'data-params=\'{ "urls" : ' +
      $(this)[0].getAttribute("data-id") +
      ' } \' data-widget="froalaextras">' +
      '<span class="fr-video fr-fvc fr-dvb fr-draggable" contenteditable="false" draggable="true">' +
      '   <iframe width="640" height="360" src="' +
      $(this)[0].getAttribute("data-link") +
      '" frameborder="0" allowfullscreen="" class="fr-draggable"></iframe>' +
      "</span>" +
      "</cassielembed>";
    editor.html.insert(html, true);
  }

  function openModal() {
    editor.modals.show(modalId);
  }

  return {
    _init: _init,
    openModal: openModal,
  };
};
