import FroalaEditor from "froala-editor";
import Dropzone from "dropzone";

import $ from "jquery";
require("jquery-ui");
require("jquery-ui/ui/widgets/sortable");

import apiList from "/src/config/endpoints.json";
let base = process.env.VUE_APP_CMS_BACKEND_BASE_URL_FROALA;
let baseAdmin = process.env.VUE_APP_CMS_BACKEND_BASE_URL;

import cassielBaseDz from "@/assets/froala/plugins/cassielBaseDz";

const PLUGIN_NAME = "cassielImageUpload";

FroalaEditor.DefineIconTemplate(
  "cassielfont",
  '<i class="icon icon-[NAME]"></i>'
);
FroalaEditor.DefineIcon(PLUGIN_NAME, {
  NAME: "uploadpicture",
  template: "cassielfont",
});
FroalaEditor.RegisterCommand(PLUGIN_NAME, {
  title: "Add Images",
  icon: PLUGIN_NAME,
  refreshAfterCallback: true,
  callback: function () {
    this[PLUGIN_NAME].openModal();
  },
});

Object.assign(FroalaEditor.DEFAULTS, {
  cassielOptimizeImagesApi: apiList.uploadEditorImageApi,
  cassielDeleteImageApi: apiList.deleteEditorImage,
  cassielRetrieveImagesApi: apiList.retrieveEditorImages,
});

FroalaEditor.PLUGINS[PLUGIN_NAME] = function (editor) {
    let dropzone;
    let modal;

    // let rand = Math.floor(Math.random() * 101);
    let modalId = PLUGIN_NAME + "Modal" + editor.id;
    let dropzoneClass = PLUGIN_NAME + "Dropzone" + editor.id;
    let insertClass = PLUGIN_NAME + "Insert" + editor.id;
    let insertOneImageClass = PLUGIN_NAME + "InsertImage" + editor.id;

    let loaded = false;

    function _init() {
        initPopup();
        $("." + insertClass).click(function () {
            insertHtml();
            editor.modals.hide(modalId);
        });

        $(document).on(
            "change",
            '[name="caption"], [name="alt"], [name="title"]',
            function () {
                let dz = new cassielBaseDz(dropzone);
                let element = $(this).closest(".dz-image-preview");
                dz.updateFile(element[0]);
            }
        );

        $(document).on(
            "click",
            "." + insertOneImageClass + ", ." + insertClass,
            function (event) {
                addImageToEditor(event);
            }
        );
    }

    function initPopup() {
        if (!modal) {
            let head =
                '<h4 data-text="true"> Image Upload Plugin </h4>' +
                '<button class="btn btn-primary ' +
                insertClass +
                '" ' +
                '       style="float:right; margin-right:50px;margin-top: 12px;">' +
                "   Insert" +
                "</button>";

            let body =
                '<div class="dropzone fr-image-list ' +
                dropzoneClass +
                '" ' +
                'style="min-height: 300px"></div>';

            let modalHash = editor.modals.create(modalId, head, body);
            modal = modalHash.$modal;

            let dropzoneElem = $("." + dropzoneClass);
            if (dropzoneElem.length === 0) {
                return;
            }

            let dz = new cassielBaseDz();
            let headers = {};
            if (typeof Storage !== "undefined") {
                headers["X-AUTH-TOKEN"] = localStorage
                    .getItem("token")
                    .replaceAll('"', "");
            }
            dropzoneElem.sortable();
            dropzone = new Dropzone("." + dropzoneClass, {
                url: baseAdmin + editor.opts.cassielOptimizeImagesApi,
                headers: headers,
                autoProcessQueue: true,
                parallelUploads: 1,
                timeout: 400000,
                thumbnailWidth: null,
                addRemoveLinks: true,
                previewTemplate:
                    "" +
                    '<div style="padding-bottom:20px;" class="dz-preview dz-file-preview">' +
                    '   <div class="dz-image dz-image-custom"><img height="120px" style="object-fit: contain" data-dz-thumbnail /></div>' +
                    '   <div class="dz-progress" style=" position: absolute; width: 96%;">' +
                    '       <span class="dz-upload" data-dz-uploadprogress></span>' +
                    "   </div>" +
                    '   <div class="dz-error-message"><span data-dz-errormessage></span></div>' +
                    '   <div class="dz-images-input">' +
                    '       <label class="text-monospace">Caption</label>' +
                    '       <textarea style="max-width: inherit; height: 70px;"' +
                    '               class="form-control border-bottom-teal" ' +
                    '               name="caption" ' +
                    '               placeholder="Caption"></textarea>' +
                    '       <label class="text-monospace">Alt</label>' +
                    '       <input style="max-width: inherit;" type="text" ' +
                    '               class="form-control border-bottom-teal" ' +
                    '               name="alt" ' +
                    '               placeholder="Alt" disabled>' +
                    '       <input type="hidden" name="image" >' +
                    "   </div>" +
                    '   <button class="dz-add btn btn-primary ' +
                    insertOneImageClass +
                    '" type="button" disabled>Add</button>' +
                    "</div>",
                init: function () {
                    this.on("sending", function (file, xhr, formData) {
                        dz.sendFile(editor, formData, file);
                    });

                    this.on("addedfile", function (file) {
                        dz.addFile(file);
                        enableInputs(file);
                    });
                },
                success: function (file, response) {
                    dz.addFile(file, response.filePath);
                    enableInputs(file);

                    file.previewElement.querySelector(".dz-progress").remove();
                },
                removedfile: function (file) {
                    dz.removeFile(editor, file, base);
                },
            });
        }
    }

    function addImageToEditor(event) {
        let preview = event.currentTarget.parentElement;
        if(preview.querySelector('input[name="image"]') !== null) {
            let image = JSON.parse(preview.querySelector('input[name="image"]').value);
            let Html = createImageHtml(base + image.url, image.caption, image.alt);
            insertImageInEditor(Html);
        }
    }


    function createImageHtml(url, caption, alt) {
        let html = '<p>';
        if (caption !== '') {
            html += '<span class="fr-img-caption fr-fil fr-dib fr-draggable" contenteditable="false" draggable="false">';
            html += '<span class="fr-img-wrap">';
        }
        html += '<img alt="' + alt + '" src="' + url + '" class=" fr-fil fr-dib fr-draggable" style="width: 100%;">';
        if (caption !== '') {
            html += '<span class="fr-inner">' + caption + '</span>';
            html += '</span>';
            html += '</span>';
        }
        html += '</p>';

        return html;
    }

    function insertImageInEditor(html) {
        editor.selection.restore();
        let prevImage = $(editor.selection.element()).closest(".fr-img-space-wrap");

        // if (prevImage.length && $(editor.selection.element()).hasClass("fr-inner")) {
        //   // Multiple images insertion
        //   // prevImage.parent().append(html);
        //     if(nextImage.length){
        //         $(html).insertAfter(nextImage);
        //     }
        //     else{
        //         $(html).insertAfter(prevImage);
        //     }
        // } else

        if (prevImage.length) {
            // Insert Image between images
            console.log("here 1")
            $(html).insertAfter(prevImage);
        } else {
            console.log("here 3")
            // First Insert
            editor.events.focus();
            editor.html.insert(html);
        }

        $(editor.selection.element())
            .closest(".form-group")
            .find("textarea")
            .val(editor.el.innerHTML);
    }

    function insertHtml() {
        let previews = $("." + dropzoneClass + " .dz-image-preview");
        let Html = '';
        for (let i = 0; i < previews.length; i++) {
            let image = JSON.parse(
                previews[i].querySelector('input[name="image"]').value
            );
            Html += createImageHtml(base + image.url, image.caption, image.alt);
        }

        insertImageInEditor(Html);
    }

    function openModal() {
        if (loaded) {
            editor.modals.show(modalId);
            return;
        }

        let formData = new FormData();
        if (editor.opts.cassielId !== null) {
            formData.append("url", editor.opts.cassielId);
        }
        formData.append("widget", editor.opts.widget);
        formData.append("field", editor.opts.entity);

        let headers = {};
        if (typeof Storage !== "undefined") {
            headers["X-AUTH-TOKEN"] = localStorage
                .getItem("token")
                .replaceAll('"', "");
        }

        $.ajax({
            processData: false,
            contentType: false,
            type: "POST",
            headers: headers,
            url: baseAdmin + editor.opts.cassielRetrieveImagesApi,
            data: formData,
            success: function (data) {
                if (data.length === 0) return;
                let images = [];

                for (let i = 0; i < data.length; i++) {
                    images.push({
                        name: "image" + i,
                        isMock: true,
                        alt: data[i].alt,
                        caption: data[i].caption,
                        url: data[i].url,
                    });
                }

                let dz = new cassielBaseDz(dropzone);
                dz.loadImages(images, base);

                loaded = true;
            },
        });

        editor.modals.show(modalId);
    }

    function enableInputs(file) {
        $(file.previewElement.querySelector("button")).removeAttr("disabled");
        $(file.previewElement.querySelector('input[name="caption"]')).removeAttr(
            "disabled"
        );
        $(file.previewElement.querySelector('input[name="alt"]')).removeAttr(
            "disabled"
        );
    }

    return {
        _init: _init,
        openModal: openModal,
    };
};
